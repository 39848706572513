import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import store from 'store2';

import { HeaderProps } from 'types/interfaces';

import Banner from 'assets/profile-banner.png';
import CompleteProfileItems from './components/CompleteProfileItems';
import CompleteProfileProgress from './components/CompleteProfileProgress';
import ConfirmMember from './components/ConfirmMember';
import ConfirmPopUp from 'components/_shared/PopUp2/components/ConfirmPopUp';
import LoadingCircle from 'components/_shared/LoadingCircle';
import PhotoPopUp from 'components/_shared/PhotoPopup';
import PopUp2 from 'components/_shared/PopUp2';
import ReferMemberPopUp from 'components/holder_profile/profile_2.0/ReferMember/components/ReferPopUp';
import RequestErrorModal from 'components/_shared/RequestErrorModal';
import Toast from 'components/_shared/Toast';
import Tooltip from './components/Tooltip';
import UnenrollHeader from './components/Unenroll';
// import { CatchUp, Ellipsis } from './components/HeaderButtons';
import { ReferMember, SaveMember } from './components/HeaderButtons';

import { isObjectEmpty } from 'util/index';

import useUpdatePublicProfile from 'hooks/useUpdatePublicProfile';

import ButtonBase from '@mui/material/ButtonBase';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        active: {
            background: '#7378E8 !important',
            color: '#fff !important'
        },
        banner: {
            display: 'flex',
            '& img': {
                minHeight: '150px',
                objectFit: 'cover',
                opacity: 0.8,
                width: '100%',
                '@media (max-width: 768px)': {
                    minHeight: '100px'
                }
            }
        },
        bannerContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: '10px',
            padding: '20px 5% 0 5%'
        },
        buttonContainer: {
            position: 'absolute',
            right: '30px',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '16px',
            margin: '10px 0',
            width: '100%',
            '@media (max-width: 928px)': {
                top: 0
            }
        },
        exited: {
            '& img': {
                boxShadow: '0 0 0 5px #FEF3E7'
            }
        },
        headerWrapper: {
            position: 'relative',
            padding: '120px 0px 50px 0px',
            '@media (max-width: 768px)': {
                padding: '100px 0 50px 0'
            }
        },
        loading: {
            pointerEvents: 'none', // Disable any pointer events when loading
            '&:hover $photoOverlay': {
                opacity: 0 // Ensure overlay doesn't show up on hover
            }
        },
        loadingCircleContainer: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'rgba(0, 0, 0, 0.8)',
            borderRadius: '50%',
            height: '100%',
            width: '100%'
        },
        memberID: {
            fontSize: '17px',
            lineHeight: '21px'
        },
        memberInfoContainer: {
            display: 'flex',
            flexDirection: 'column',
            color: '#1F2152'
        },
        memberName: {
            fontSize: '28px',
            fontWeight: 700,
            lineHeight: '34px',
            marginBottom: '10px'
        },
        pendingContainer: {
            display: 'flex',
            justifyContent: 'center',
            background: '#F8E8E8',
            color: '#E1625E',
            padding: '8px 0',
            textTransform: 'uppercase',
            width: '100%',
            '@media (max-width: 968px)': {
                '& div': {
                    paddingLeft: '30%'
                }
            },
            '@media (max-width: 550px)': {
                fontSize: '14px',
                '& div': {
                    paddingLeft: '35%'
                }
            }
        },
        pendingLabel: {},
        photoContainer: {
            position: 'absolute',
            left: '5%',
            top: '-60px',
            cursor: 'pointer',
            '&:hover $photoOverlay': {
                opacity: 1,
                pointerEvents: 'auto',
                transition: 'opacity 0.5s ease, visibility 0s',
                visibility: 'visible'
            },
            '& img': {
                borderRadius: '50%',
                boxShadow: '0 0 0 5px #fff',
                height: '160px',
                objectFit: 'cover',
                width: '160px'
            },
            '@media (max-width: 768px)': {
                left: '10%',
                top: '-25%'
            }
        },
        photoOverlay: {
            position: 'absolute',
            top: 0,
            zIndex: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '50%',
            color: '#fff',
            height: '160px',
            opacity: 0,
            pointerEvents: 'none',
            transition: 'opacity 0.5s ease, visibility 0s 0.5s',
            visibility: 'hidden',
            width: '160px',
            '& i': {
                fontSize: '24px'
            }
        }
    })
);

const Header: React.FC<HeaderProps> = ({
    caseloads,
    // catchUpItems,
    confirmUser,
    createMemberCaseload,
    deploymentOrgs,
    exited,
    holder,
    pending,
    profileItems,
    removeMemberFromMyMembers,
    saveMemberToMyMembers,
    setCompleteProfilePopup,
    // setQuickCatchUp,
    setReenroll,
    setReferMemberPopUp,
    // setShowCatchUp,
    userID
}) => {
    const classes = useStyles();
    const isInitialRender = useRef(true);

    const { isError, isLoading, reset, updatePublicProfile } =
        useUpdatePublicProfile();

    const {
        first_name,
        id,
        last_name,
        member_id,
        middle_name,
        mobile_photo,
        name,
        photo,
        unenrolled_on
    } = holder;

    const activeReferral = caseloads?.find(
        (caseload) =>
            caseload.homeless_id === id && caseload.status === 'pending'
    );
    const caseload = caseloads?.find((caseload) => caseload.homeless_id === id);
    const getLocalStorageTooltip = store.get('renderSaveMemberTooltip');
    const hasViewedTooltip =
        getLocalStorageTooltip && getLocalStorageTooltip[userID] ? true : false;
    const isSaved = caseloads?.some(
        (caseload: { homeless_id: number; status: string }) =>
            caseload.homeless_id === id && caseload.status === 'active'
    );
    const profilePercentage =
        (Object.keys(profileItems).filter(
            (item) =>
                profileItems[item].status === 'complete' ||
                profileItems[item].status === 'pending'
        ).length /
            Object.keys(profileItems).length) *
        100;

    const [confirmMemberPopUp, setConfirmMemberPopUp] = useState(false);
    const [photoPopUp, setPhotoPopUp] = useState(false);
    const [newProfilePicture, setNewProfilePicture] = useState(null);
    const [memberMenu, setMemberMenu] = useState(false);
    const [saved, setSaved] = useState(isSaved);
    const [showNotification, setShowNotification] = useState(false);
    const [showTooltip, setShowTooltip] = useState(true);

    const photoContainerClass = classNames({
        [classes.exited]: exited,
        [classes.photoContainer]: true
    });

    const updateProfilePhoto = useCallback(() => {
        const changedState = {} as { photo: Blob };
        const _formData = new FormData();

        if (newProfilePicture) {
            changedState['photo'] = newProfilePicture;
        }

        if (!isObjectEmpty(changedState)) {
            for (const [key, value] of Object.entries(changedState)) {
                _formData.append(`samaritan_member[${key}]`, value);
            }

            updatePublicProfile(id, _formData);
        }
    }, [id, newProfilePicture, updatePublicProfile]);

    const renderMemberName = () => {
        const trimmedFirstName = (first_name || '').trim();
        const trimmedLastName = (last_name || '').trim();
        const trimmedMiddleName = (middle_name || '').trim();

        if (!trimmedFirstName && !trimmedMiddleName && !trimmedLastName) {
            return name;
        }

        return `${trimmedFirstName} ${trimmedMiddleName} ${trimmedLastName}`.trim();
    };

    const memberMenuProps = {
        activeReferral,
        caseload,
        caseloadID: caseload?.id || null,
        createMemberCaseload,
        deploymentOrgs,
        hasViewedTooltip,
        isSaved,
        memberID: id,
        removeMemberFromMyMembers,
        saved,
        saveMemberToMyMembers,
        setMemberMenu,
        setReferMemberPopUp,
        showTooltip,
        setSaved
    };

    useEffect(() => {
        if (!isInitialRender.current) {
            setShowNotification(true);

            const timeout = setTimeout(() => {
                setShowNotification(false);
            }, 3000);

            return () => clearTimeout(timeout);
        } else {
            isInitialRender.current = false;
        }
    }, [saved]);

    useEffect(() => {
        if (newProfilePicture) {
            updateProfilePhoto();
        }
    }, [newProfilePicture, updateProfilePhoto]);

    return (
        <>
            <Toast
                icon="fa-check-circle"
                message={
                    <>
                        {name} is {saved ? 'saved to' : 'removed from'} your{' '}
                        <b>"My Members"</b> list
                    </>
                }
                open={showNotification}
                severity={saved ? 'success' : 'warning'}
            />
            <div className={classes.banner}>
                <img src={Banner} alt="banner" />
            </div>
            {pending && (
                <div className={classes.pendingContainer}>
                    <div className={classes.pendingLabel}>
                        <span style={{ fontWeight: 700 }}>
                            MEMBERSHIP STATUS:{' '}
                        </span>
                        <span style={{ fontWeight: 800 }}>PENDING</span>
                    </div>
                </div>
            )}
            {exited && (
                <UnenrollHeader
                    unenrolled_on={unenrolled_on}
                    setReenroll={setReenroll}
                />
            )}
            {!caseload && !hasViewedTooltip && showTooltip && (
                <Tooltip
                    name={name}
                    userID={userID}
                    setShowTooltip={setShowTooltip}
                />
            )}
            {/* {!exited && (
                <div className={classes.buttonContainer}>
                    {catchUpItems.length > 0 && (
                        <CatchUp
                            setQuickCatchUp={setQuickCatchUp}
                            setShowCatchUp={setShowCatchUp}
                        />
                    )}
                    <Ellipsis setShowMemberMenu={setMemberMenu} />
                </div>
            )} */}
            <div className={classes.buttonContainer}>
                {!exited && deploymentOrgs?.length && (
                    <ReferMember
                        isSaved={isSaved}
                        setReferMemberPopup={setReferMemberPopUp}
                    />
                )}
                {!exited && (
                    <SaveMember
                        activeReferral={activeReferral}
                        caseload={caseload}
                        caseloadID={caseload?.id || null}
                        createMemberCaseload={createMemberCaseload}
                        hasViewedTooltip={hasViewedTooltip}
                        isSaved={isSaved}
                        memberID={id}
                        removeMemberFromMyMembers={removeMemberFromMyMembers}
                        saved={saved}
                        saveMemberToMyMembers={saveMemberToMyMembers}
                        showTooltip={showTooltip}
                        setSaved={setSaved}
                    />
                )}
            </div>
            <div className={classes.headerWrapper}>
                {!exited &&
                    (!pending || !confirmUser) &&
                    profilePercentage !== 100 && (
                        <CompleteProfileItems
                            profileItems={profileItems}
                            setCompleteProfilePopup={setCompleteProfilePopup}
                        />
                    )}
                {pending && confirmUser && (
                    <ConfirmMember
                        setConfirmMemberPopUp={setConfirmMemberPopUp}
                    />
                )}
                <div
                    className={`${photoContainerClass} ${
                        isLoading ? classes.loading : ``
                    }`}
                >
                    {isLoading && (
                        <div className={classes.loadingCircleContainer}>
                            <LoadingCircle />
                        </div>
                    )}
                    <img
                        src={mobile_photo}
                        alt="profile"
                        style={
                            pending ? { boxShadow: '0 0 0 5px #F8E8E8' } : {}
                        }
                    />
                    <ButtonBase
                        className={classes.photoOverlay}
                        disabled={isLoading}
                        component="div"
                        onClick={() => !isLoading && setPhotoPopUp(true)}
                    >
                        <i className="fas fa-pencil" />
                    </ButtonBase>
                    {!exited && !pending && (
                        <CompleteProfileProgress
                            percentage={profilePercentage}
                        />
                    )}
                </div>
                <div className={classes.bannerContainer}>
                    <div className={classes.memberInfoContainer}>
                        <span className={classes.memberName}>
                            {renderMemberName()}
                        </span>
                        <span className={classes.memberID}>
                            Insurance ID: {member_id ? member_id : '-'}
                        </span>
                    </div>
                </div>
            </div>
            {isError && (
                <RequestErrorModal
                    open={isError}
                    onClose={() => reset()}
                    message="Changes couldn't be saved."
                    retryFunc={updateProfilePhoto}
                />
            )}
            {confirmMemberPopUp && (
                <PopUp2
                    onClose={() => setConfirmMemberPopUp(false)}
                    open={confirmMemberPopUp}
                    photo={photo}
                    title="confirm"
                >
                    <ConfirmPopUp
                        holder={holder}
                        onClose={() => setConfirmMemberPopUp(false)}
                    />
                </PopUp2>
            )}
            {memberMenu && (
                <PopUp2
                    customClasses={{ content: { gap: '0', padding: '0' } }}
                    onClose={() => setMemberMenu(false)}
                    open={memberMenu}
                    title="refer"
                >
                    <ReferMemberPopUp {...memberMenuProps} />
                </PopUp2>
            )}
            {photoPopUp && (
                <PhotoPopUp
                    fromProfilePhoto={true}
                    profilePhotoURL={photo}
                    handleModalClick={() => setPhotoPopUp(false)}
                    setProfilePicture={setNewProfilePicture}
                />
            )}
        </>
    );
};

export default Header;
